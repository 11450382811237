import React, { useEffect, useState } from "react";
import style from "./range.module.css";
import { useDispatch, useSelector } from "react-redux";
import { changeQuestionScaleAction } from "../../Redux/AnswersReducer";

const Range = ({ number, name, currentGlobalQuestionsIndex }) => {
  const dispatch = useDispatch();
  const { answers } = useSelector((state) => state.answers);

  return (
    <span
      style={
        answers[currentGlobalQuestionsIndex]?.list.find(
          (li) => li.range == number && li.name == name
        )
          ? { background: "#00457c", color: "white" }
          : {}
      }
      onClick={() => {
        dispatch(changeQuestionScaleAction(name, number));
      }}
      className={style.round}
    >
      {number}
    </span>
  );
};

export default Range;
