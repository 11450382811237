import React from "react";
import style from "./welcome.module.css";

const TestParssed = ({}) => {
  return (
    <section className={style.main}>
      <h2 style={{ marginBottom: "0", margin: "50px" }}>
        Кажется Вы уже проходили данный тест!
      </h2>
    </section>
  );
};

export default TestParssed;
