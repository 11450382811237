import { combineReducers, legacy_createStore as createStore } from "redux";
import questionsState from "./QuestionsReducer";
import answersState from "./AnswersReducer";


const reducers = combineReducers({
    questions: questionsState,
    answers: answersState
});

let store = createStore(reducers);

window.store = store;

export default store;
