const INITIAL_STATE = "INITIAL_STATE";

let initialStateQuestions = {
  questions: [],
};

const questionsState = (state = initialStateQuestions, action) => {
  switch (action.type) {
    case INITIAL_STATE:
      return {
        ...state,
        questions: action.questions,
      };
    default:
      return state;
  }
};

export const initialStateAction = (questions) => {
  return { type: INITIAL_STATE, questions };
};

export default questionsState;
