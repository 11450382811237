import { modalTypes } from "../types";

const INIT_ANSWER_STATE = "INIT_ANSWER_STATE";
const CHANGE_QUESTION_SCALE = "CHANGE_QUESTION_SCALE";
const CHECKED_VARIANTS = "CHECKED_VARIANTS";
const CHECKED_VARIANT = "CHECKED_VARIANT";
const CHECKED_DONT_SHOW_VARIANT = "CHECKED_DONT_SHOW_VARIANT";
const ADD_FREE_ANSWER = "ADD_FREE_ANSWER";
const ADD_FREE_ANSWER_OTHER_VARIANT = "ADD_FREE_ANSWER_OTHER_VARIANT";
const PUSH_DONT_SHOW = "PUSH_DONT_SHOW";
const SET_GIFT = "SET_GIFT";
const SET_PERSONAL_DATA = "SET_PERSONAL_DATA";

let initialStateAnswers = {
  answers: [],
  dontShow: [],
  personalData: {
    firsName: "",
    lastName: "",
    middleName: "",
    email: "",
    phoneNumber: "",
    gift: "",
  },
};

const answersState = (state = initialStateAnswers, action) => {
  switch (action.type) {
    case INIT_ANSWER_STATE:
      return {
        ...state,
        answers: action.answers.filter((el) => {
          if (el.type === modalTypes.questionsOnScale) {
            for (let i = 0; i < el.list?.length; i++) {
              if (el.list[i].range) {
                el.list[i].range = 0;
              }
            }
          }
          return el;
        }),
      };
    case CHANGE_QUESTION_SCALE:
      return {
        ...state,
        answers: state.answers.filter((el) => {
          if (el.type === modalTypes.questionsOnScale) {
            // надо будет добавить уточнение какой именно элемент по индексу, так как в случае если их будет больше чем 1 то меняться будут все значения
            el.list.filter((li) => {
              if (action.name) {
                if (li.name === action.name) {
                  li.range = action.range;
                }
              } else {
                li.range = action.range;
              }
              return li;
            });
          }
          return el;
        }),
      };
    case CHECKED_VARIANTS:
      return {
        ...state,
        answers: state.answers.filter((el) => {
          if (
            el.type === modalTypes.questionsChoiceOfOptionsMany &&
            el.blockName === action.blockName
          ) {
            el.list.filter((li) => {
              if (action.name) {
                if (li.name === action.name) {
                  li.isChecked = !li.isChecked;
                  if (li.onliThis != undefined) {
                    li.onliThis = !li.onliThis;
                  } else {
                    el.list.filter((li) => {
                      if (li?.onliThis) li.onliThis = false;
                    });
                  }
                }
              } else {
                li.isChecked = false;
              }
              return li;
            });
          }
          return el;
        }),
      };
    case CHECKED_VARIANT:
      return {
        ...state,
        answers: state.answers.filter((el) => {
          if (
            el.type === modalTypes.questionsChoiceOfOptionsOne &&
            el.blockName === action.blockName
          ) {
            el.list.filter((li) => {
              if (action.name) {
                if (li.isChecked && li.name !== action.name) {
                  li.isChecked = false;
                }
                if (li.name === action.name) {
                  li.isChecked = !li.isChecked;
                }
              } else {
                li.isChecked = false;
              }
              return li;
            });
          }
          return el;
        }),
      };
    case CHECKED_DONT_SHOW_VARIANT:
      return {
        ...state,
        answers: state.answers.filter((el) => {
          if (el.blockName === action.blockName) {
            el.list.filter((li) => {
              if (li?.isChecked != undefined) {
                return (li.isChecked = !li.isChecked);
              }
            });
          }
          return el;
        }),
      };
    case ADD_FREE_ANSWER_OTHER_VARIANT:
      return {
        ...state,
        answers: state.answers.filter((el) => {
          if (el.blockName === action.blockName) {
            el.list.filter((li) => {
              if (li.answer != undefined) {
                return (li.answer = action.answer);
              }
            });
            return el;
          }
          return el;
        }),
      };
    case ADD_FREE_ANSWER:
      return {
        ...state,
        answers: state.answers.filter((el) => {
          if (
            el.type === modalTypes.freeAnswer &&
            el.blockName === action.blockName
          ) {
            el.answer = action.answer;
            return el;
          }
          return el;
        }),
      };
    case PUSH_DONT_SHOW:
      return {
        ...state,
        dontShow: state.dontShow.find((num) => num === action.dontShow)
          ? state.dontShow.filter((num) => num != action.dontShow)
          : [...state.dontShow, action.dontShow],
      };
    case SET_GIFT:
      return {
        ...state,
        personalData:
          state.personalData.gift != action.gift
            ? { ...state.personalData, gift: action.gift }
            : { ...state.personalData, gift: "" },
      };
    case SET_PERSONAL_DATA:
      return {
        ...state,
        personalData: action.firsName
          ? { ...state.personalData, firsName: action.firsName }
          : action.lastName
          ? { ...state.personalData, lastName: action.lastName }
          : action.middleName
          ? { ...state.personalData, middleName: action.middleName }
          : action.email
          ? { ...state.personalData, email: action.email }
          : { ...state.personalData, phoneNumber: action.phoneNumber },
      };
    default:
      return state;
  }
};

export const initAnswerStateAction = (answers) => {
  return { type: INIT_ANSWER_STATE, answers };
};
export const changeQuestionScaleAction = (name = null, range = 0) => {
  return { type: CHANGE_QUESTION_SCALE, name, range };
};
export const chackedVariantsAction = (blockName, name = null) => {
  return { type: CHECKED_VARIANTS, blockName, name };
};
export const chackedVariantAction = (blockName, name = null) => {
  return { type: CHECKED_VARIANT, blockName, name };
};
export const addFreeAnswerAction = (blockName, answer) => {
  return { type: ADD_FREE_ANSWER, blockName, answer };
};
export const addFreeAnswerOtherVariantAction = (blockName, answer) => {
  return { type: ADD_FREE_ANSWER_OTHER_VARIANT, blockName, answer };
};
export const checkedDontShowVariantAction = (blockName) => {
  return { type: CHECKED_DONT_SHOW_VARIANT, blockName };
};
export const pushDontShowActionAction = (dontShow = null) => {
  return { type: PUSH_DONT_SHOW, dontShow };
};
export const setGiftAction = (gift) => {
  return { type: SET_GIFT, gift };
};
export const setPersonalDataAction = (
  firsName = "",
  lastName = "",
  middleName = "",
  email = "",
  phoneNumber = ""
) => {
  return {
    type: SET_PERSONAL_DATA,
    firsName,
    lastName,
    middleName,
    email,
    phoneNumber,
  };
};

export default answersState;
