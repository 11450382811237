import { modalTypes } from "./types";

const questions = [
  {
    blockName:
      "Какие темы об инфекциях почек и мочевых путей Вам наиболее интересны? Оцените интерес по шкале от 1 до 5, где (5 – очень интересно, 1 – совсем не интересно)",

    type: modalTypes.questionsOnScale,
    list: [
      {
        name: "Диагностика инфекций мочевыделительной системы",
        range: 5,
      },
      {
        name: "Терапия и ведение пациентов с инфекциями мочевыделительной системы",
        range: 5,
      },
      {
        name: "Клинические рекомендации",
        range: 5,
      },
      {
        name: "Клинические исследования",
        range: 5,
      },
      {
        name: "Анонсы мероприятий и встреч с экспертами",
        range: 5,
      },
      {
        name: "Новости и научные публикации",
        range: 5,
      },
      {
        name: "Разбор клинических случаев",
        range: 5,
      },
      {
        name: "Другое (ваши варианты ответа.)",
        answer: "",
      },
      {
        name: "Не интересны темы, посвященные инфекциям почек и мочевых путей",
        range: null,
        isChecked: false,
      },
    ],
  },
  {
    blockName:
      "Какие сайты вы используете для получения профессиональной информации?",
    type: modalTypes.questionsChoiceOfOptionsMany,
    list: [
      { name: "Медвестник", isChecked: false },
      {
        name: "Русский Медицинский Журнал (РМЖ)",
        isChecked: false,
      },
      { name: "Uroweb", isChecked: false },
      { name: "Доктор на работе", isChecked: false },
      { name: "Лечащий врач", isChecked: false },

      { name: "Не использую такие сайты", onliThis: false, isChecked: false },
      {
        name: "Другое (ваши варианты ответа:)",
        answer: "",
        isChecked: false,
      },
    ],
  },
  {
    blockName:
      "Знаете ли Вы сайты, посвященные проблеме инфекций мочевыводящих путей или МКБ?",
    type: modalTypes.questionsChoiceOfOptionsOne,
    list: [
      { name: "Да, какие именно:", answer: "", isChecked: false },
      {
        name: "Нет, такие сайты мне не интересны",
        isChecked: false,
      },
      {
        name: "Нет, но такой сайт был бы мне интересен",
        isChecked: false,
      },
    ],
  },
  {
    blockName: "Какие группы в соцсетях вы используете по работе (Vk и Tg)?",
    type: modalTypes.questionsChoiceOfOptionsMany,
    list: [
      { name: "Урологи (https://vk.com/urology_1)", isChecked: false },
      {
        name: "Урология | Андрология | Консультации (https://vk.com/uro_andromed)",
        isChecked: false,
      },
      { name: "Комьюнити в Tg «Уродинаторская»", isChecked: false },
      { name: "Медицина (https://vk.com/medrussia)", isChecked: false },
      {
        name: "Provrach. Будни медицины (https://t.me/provrach)",
        isChecked: false,
      },

      { name: "Не использую такие группы", onliThis: false, isChecked: false },
      {
        name: "Другое (ваши варианты ответа/)",
        answer: "",
        isChecked: false,
      },
    ],
  },
  {
    blockName:
      "Какие препараты, помимо антибиотиков, Вы назначаете пациентам с инфекциями почек и мочевых путей? Перечислите все, что вспомните, включая названия брендов и действующих веществ?",
    type: modalTypes.freeAnswer,
    answer: "",
  },
  {
    blockName: "По каким критериям Вы выбираете эти препараты?",
    type: modalTypes.freeAnswer,
    answer: "",
  },
  {
    blockName:
      "Какая доля Ваших пациентов получает назначение Канефрона при инфекциях почек и мочевых путей",
    type: modalTypes.questionsChoiceOfOptionsOne,
    list: [
      { name: "менее 25%:", isChecked: false },
      {
        name: "менее 50%:",
        isChecked: false,
      },
      { name: "менее 75%:", isChecked: false },
      {
        name: "более 75%:",
        dontShow: 8,
        isChecked: false,
      },
      {
        name: "не назначаю Канефрон.",
        isChecked: false,
      },
    ],
  },
  {
    blockName:
      "Что не позволяет назначать Канефрон чаще при инфекциях почек и мочевых путей?",
    type: modalTypes.freeAnswer,
    answer: "",
  },
  {
    blockName:
      "Какая доля ваших пациентов получает назначение Канефрона при мочекаменной болезни",
    type: modalTypes.questionsChoiceOfOptionsOne,
    list: [
      { name: "менее 25%", isChecked: false },
      {
        name: "менее 50%",
        isChecked: false,
      },
      { name: "менее 75%", isChecked: false },
      {
        name: "более 75%",
        dontShow: 10,
        isChecked: false,
      },
      {
        name: "не назначаю Канефрон",
        isChecked: false,
      },
    ],
  },

  {
    blockName:
      "Что не позволяет назначать Канефрон чаще при мочекаменной болезни?",
    type: modalTypes.freeAnswer,
    answer: "",
  },
  {
    blockName:
      "В каких активностях на образовательных очных мероприятиях, помимо научной программы, вы хотели бы принять участие?",
    type: modalTypes.questionsChoiceOfOptionsMany,
    list: [
      { name: "Медицинские квизы (викторины)", isChecked: false },
      {
        name: "Деловые ужины с коллегами",
        isChecked: false,
      },
      { name: "Экскурсии", isChecked: false },
      { name: "Квесты", isChecked: false },
      {
        name: "Театры",
        isChecked: false,
      },
      {
        name: "Ваши варианты ответа",
        answer: "",
        isChecked: false,
      },
    ],
  },
];

export let questionsClone = structuredClone(questions);
