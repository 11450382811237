import React from "react";
import style from "./welcome.module.css";
import TestParssed from "./TestPassed";

const WelcomeComponent = ({ start, isCompleted }) => {
  if (isCompleted) {
    return <TestParssed />;
  }
  return (
    <section className={style.main}>
      <h2>Добро пожаловать на опрос от компании Бионорика</h2>
      <p>
        Прохожнение опроса займет не более 4 минут! 50 счастливчиков, прошедших
        опрос получат подарочные сертификаты номиналом 1000 рублей от компаний:
      </p>
      <ul>
        <li>Ozon.ru</li>
        <li>mbookshop.ru (медицинская литература)</li>
        <li>lechikrasivo.ru (медицинская одежда)</li>
        <li>drvorobev.ru (медицинский ювелирный бренд)</li>
      </ul>
      <button
        onClick={() => {
          start(true);
        }}
      >
        Начать
      </button>
    </section>
  );
};

export default WelcomeComponent;
